import React, { useEffect, useState } from "react";
import NewsData from "../../Components/News/NewsData";
import { Link, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faInstagram, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons/faChevronRight";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import Footer from "../../Components/Footer/Footer";
const NewsSinglePage = () => {
    const [news, setNews] = useState({});
    const { id } = useParams();
    useEffect(() => {
        setNews(NewsData.find(n => n.id == id));
        // console.log(NewsData.find(n=>n.id));
    }, [])
    if (!news) {
        return (
            <>
                <div className="Center mt-5 mb-5">
                    <h5>
                        No Data Found
                    </h5>
                </div>
                <Footer/>
            </>
        );
    }
    return (
        <div className="NewsSinglePage">
            <section className="SingleBlogSection" id="blog-detail">
                <div className="container">
                    {news?.tags?.map((tag) => (
                        <span className="badge bg-secondary m-2">{tag}</span>
                    ))}
                    <div className="row" id="blog-card-details">
                        <div className="col-lg-12">
                            <div className="SingleBlogImageContainer CenterFlex">
                                <img src={news?.image} alt="" />
                            </div>
                        </div>
                        <div className="col-lg-12 mt-2 SingleBlogTextContainer">
                            <h3 className="SingleBlogTitle">
                                {news?.title}
                            </h3>
                            <span className="card-date text-muted p-4">
                                {/* January 02, 2020 */}
                                {news?.date &&
                                    news?.date
                                }
                            </span>
                            <div
                                className="NewsDesc"
                                dangerouslySetInnerHTML={{
                                    __html: news?.description || "",
                                }}
                            ></div>
                        </div>
                        <div className="col-lg-12">
                            <hr></hr>
                        </div>
                        <div className="col-lg-12 ShareSingleBlogPage">
                            <div className="d-flex justify-content-between align-items-center">
                                <div className="social-icons">
                                    <span>Share:</span>
                                    <Link href="#" className="text-muted m-2">
                                        <i className="fab fa-facebook"></i>
                                        <FontAwesomeIcon icon={faFacebook} />

                                    </Link>
                                    <Link href="#" className="text-muted m-2">
                                        <FontAwesomeIcon icon={faLinkedin} />

                                    </Link>
                                    <Link href="#" className="text-muted m-2">
                                        <FontAwesomeIcon icon={faInstagram} />

                                    </Link>
                                </div>
                                <Link to='/news' className="text-decoration-none BackToNewsBtn">
                                    <FontAwesomeIcon icon={faChevronLeft} /> Back to News

                                </Link>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
            <Footer />
        </div>
    );
}
export default NewsSinglePage;