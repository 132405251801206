import React, { useState } from "react";
import JobCard from "./JobCard";

const JobsSection = () => {
    const jobs = [
        {
            id: 1,
            title: 'UI/UX - Graphic Designer',
            About: 'This is a full-time on-site role located in Nasr city, Cairo, Egypt for a Graphic Designer. The Graphic Designer will be responsible for all the needed designs internally and externally for our clients. S/he will work closely with other teams to achieve the required work.',
            RoleScope: [
                'Meet with Account Managers or clients to discuss the clients’ requirements.',
                'Generate new ideas and design Projects, Campaigns, or Concepts. Also, Design presales materials and proposal elements.',
                'Design Social assets for Facebook, Instagram, YouTube, Google, and Twitter, etc.',
                'Be able to design and prepare full CIMs (Corporate Identity Manuals).',
                'Develop graphics and layouts for product illustrations and company logos.',
                'Develop an initial interactive design that conveys the required message.',
                'Present finalized ideas and concepts to account managers or clients and receive feedback.',
                'Incorporate changes requested by the client into a final design.',
                'Drive and initialize the ideation, conceptualization, and crafting of campaigns / visual strategy.',
                'Design print promotional material such as flyers, posters, ads, signage, newsletters, mockups, etc.',
                'Prepare all required designs to be print-ready.',
                'UI/UX design for websites and mobile applications.',
            ],
            MinimumRequirements: [
                'Bachelor degree in a related field and/or equivalent.',
                '2-4 years of experience in a similar position.',
                'Must be a creative thinker.',
                'Expert knowledge of design tools: Adobe Photoshop, Adobe Premiere, Adobe Illustrator, After Effects, Figma, Adobe XD, etc.',
                'In-depth knowledge and understanding of social media platforms design and UI/UX principles.',
                'Good technical understanding with the ability to pick up new tools quickly.',
                'Previous experience in a digital agency is a plus.',
                'Females only is required for this position.'
            ],
            RequiredSkills: [
                'Good communication skills.',
                'Strong problem-solving skills.',
                'Team worker.',
                'Very comfortable learning new technologies, tools, and platforms.',
                'Highly motivated.',
                'Initiative and passionate.',
                'English fluency is a must'
            ]
        },
        {
            id: 2,
            title: 'Product Manager / Business Developement Manager',
            About: 'We are seeking an entrepreneurial-minded Product Manager with a strong business development focus to lead the growth and market expansion of a mobile app designed to streamline communication between nurseries and parents.',
            RoleScope: [
                'Business Development & Market Expansion',
                'Identify new business opportunities, potential partners, and distribution channels to grow the app’s user base.',
                'Build and maintain strong relationships with nurseries, childcare centers, and industry partners to promote app adoption.',
                'Develop and implement go-to-market strategies to expand into new markets and regions.',
                'Lead initiatives to drive customer acquisition, retention, and overall market penetration.',
                'Partnerships & Collaborations',
                'Create strategic partnerships with nurseries, early childhood education institutions, and other relevant stakeholders.',
                'Negotiate and establish agreements with nurseries and childcare providers to integrate the app into their communication systems.',
                'Revenue Growth & Monetization',
                'Develop pricing strategies, revenue models, and monetization opportunities for the mobile app, ensuring sustainable growth.',
                'Analyze market trends to identify new revenue streams, including subscription models, premium features, and advertising opportunities.',
                'Customer Insights & Market Research',
                'Conduct market research to understand customer needs, competitive landscape, and industry trends.',
                'Product Positioning & Brand Development',
                'Collaborate with the marketing team to develop compelling messaging, branding, and positioning for the mobile app.',
                'Stakeholder Management & Communication',
                'Serve as the primary liaison between the development team, marketing, sales, and external partners.'
            ],
            MinimumRequirements: [
                'Bachelor’s degree in Business, Marketing, or a related field.',
                '3-5+ years of experience in product management, business development, or market expansion, ideally in the education, childcare, or technology sectors.',
                'Strong understanding of customer acquisition strategies and revenue growth models.',
                'Proven track record in developing and executing successful business development and go-to-market strategies.',
                'Experience working with external partners, negotiating deals, and managing relationships.',
                'Females only is required for this position'
            ],
            RequiredSkills: [
                'Experience in the education or childcare industry is a plus.',
                'Knowledge of B2B and B2C marketing strategies, especially in technology-driven solutions.',
                'Familiarity with business analytics tools to measure and optimize growth strategies.',
                'Ability to work in a fast-paced, dynamic environment while managing multiple priorities.'
            ]
        },
        {
            id: 3,
            title: 'Social Media Marketing Executive',
            About: 'This is a full-time on-site role located in Nasr city, Cairo, Egypt for a Social Media Marketing Executive. The Social Media Marketing Executive will be responsible for creating and executing social media content plans, managing social media channels, and engaging with audiences and clients.',
            RoleScope: [
                'Contributes to the marketing strategy by leveraging social media to identify and acquire customers.',
                'Develop content strategies and plans that match the clients’ goals (Including Marketing Research, SWOT Analysis).',
                'Create content in Arabic and English that helps in generating more leads and gaining more traffic.',
                'Develop well-prepared proposals which identify the key social media activities that would assist in achieving the clients’ objectives.',
                'Maintaining social media presence across all digital channels.',
                'To build a full plan with content creation to cover campaign objectives & syncing with designers to provide relevant designs.',
                'Brainstorm new and creative growth strategies.',
                'Stay up to date with the latest social media best practices and technologies.',
                'Manage day-to-day development of editorial content on social media platforms, including posts and discussion.',
                'Researching industry-related engaging topics.',
                'Participate in planning and strategizing for inbound marketing campaign execution.',
                'Develop benchmarks and goals for each campaign and ensure they are reached.',
                'Generate new ideas and opportunities to ensure client success.',
                'Media buying knowledge across all social media platforms.',
                'SEO & Google Ads knowledge.'
            ],
            MinimumRequirements: [
                'Bachelor degree in related area and/or equivalent.',
                '2-4 years of experience in a similar position.',
                'Digital Marketing and Marketing skill.',
                'Experience with social media management tools and analytics.',
                'Females only is required for this position'
            ],
            RequiredSkills: [
                'Good communication skills.',
                'Strong problem-solving skills.',
                'Team worker.',
                'Very comfortable learning new technologies, tools, and platforms.',
                'Highly motivated.',
                'Initiative and passionate.',
                'English fluency is a must'
            ]
        },
        {
            "id": 4,
            "title": "PHP/Laravel Backend Developer",
            "About": "We are seeking a talented PHP/Laravel Backend Developer to design, develop, and maintain robust and scalable backend solutions. The ideal candidate will have extensive experience with PHP and the Laravel framework, along with a strong understanding of database management and API development.",
            "RoleScope": [
                "Design, develop, and maintain server-side applications using PHP and the Laravel framework.",
                "Write clean, scalable, and efficient code.",
                "Optimize applications for maximum speed and scalability.",
                "Implement security and data protection measures.",
                "Collaborate with front-end developers to integrate user-facing elements with server-side logic.",
                "Troubleshoot and debug applications.",
                "Perform code reviews and provide constructive feedback.",
                "Create and maintain documentation for developed APIs and systems.",
                "Stay up-to-date with emerging technologies and industry trends.",
                "Handle queries with MySQL."
            ],
            "MinimumRequirements": [
                "Bachelor's degree in Computer Science, Engineering, or a related field.",
                "Minimum of 2 years proven experience as a Backend Developer with a focus on PHP and Laravel.",
                "Strong understanding of the Laravel framework and its ecosystem.",
                "Experience with database technologies such as MySQL.",
                "Proficiency in designing RESTful APIs and web services.",
                "Strong understanding of object-oriented programming and software design principles.",
                "Excellent problem-solving skills and attention to detail.",
                "Ability to work collaboratively in a team environment.",
                "Strong communication and interpersonal skills."
            ],
            "RequiredSkills": [
                "Experience with front-end technologies (HTML, CSS, JavaScript) to facilitate better collaboration with front-end developers.",
                "Experience with microservices architecture.",
                "Hosting and server deployment."
            ]
        },
        {
            "id": 5,
            "title": "Graphic Designer",
            "About": "MIND for Information Technology & Media is a comprehensive solution provider for marketing, advertising, and PR needs. They offer services in Information Technology, Digital Marketing, Media Production, and more. The Graphic Designer role involves creating and designing graphics, logos, branding materials, and marketing visuals.",
            "RoleScope": [
                "Create and design graphics, logos, and branding materials.",
                "Work with typography to create visually appealing designs.",
                "Design marketing materials, website graphics, and social media visuals.",
                "Collaborate with team members to meet project goals and deadlines.",
                "Ensure designs align with marketing and advertising principles."
            ],
            "MinimumRequirements": [
                "Graphics, Graphic Design, and Logo Design skills.",
                "Experience in branding and typography.",
                "Proficiency in design software and tools.",
                "Creativity and attention to detail.",
                "Strong portfolio showcasing design skills.",
                "Excellent communication and collaboration skills.",
                "Ability to work in a fast-paced environment.",
                "Degree in Graphic Design or a related field."
            ],
            "RequiredSkills": [
                "Familiarity with marketing and advertising principles.",
                "Strong creativity and ability to deliver unique designs.",
                "Proficiency in industry-standard design tools and software."
            ]
        },
        
        
        
        
    ];
    

    return (
        <section className="JobsSection container">
            <div className="JobsHeader">
                <h2>Current Vacancies</h2>
            </div>
            <div className="row">
                {jobs.length === 0 ? (
                    <div className="NoJobs">
                        <span>We currently have no job openings</span>
                    </div>
                ) : (
                    jobs.map(job => <JobCard key={job.id} job={job} />)
                )}
            </div>
        </section>
    );
};



export default JobsSection;
